




















import Vue from 'vue'

export default Vue.extend({
  props: {
    username: {
      type: String,
      default: null,
    },
  },
})
